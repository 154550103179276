<template>
  <v-app>
    <div class="d-flex">
      <div>
        <s-navigation-drawer class="app-drawer" app>
          <img
            class="pl-4 pt-8 pb-6"
            :src="require(`@/assets/logo.svg`)"
            alt="app logo"
          />
          <v-list
            dense
            class="nav-links"
          >
            <template
              v-for="(item, index) in items"
            >
              <v-list-group
                v-if="item.subs && item.subs.length > 0"
                :key="item.text"
                :value="isNavActive(item.to)"
                class="nav-link__sub-group__parent"
                active-class="active"
                no-action
              >
                <template v-slot:activator>
                  <v-list-item-action>
                    <s-icon
                      class="nav-link__icon"
                      :name="item.icon"
                      size="26px"
                    ></s-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item-content>
                </template>

                <router-link
                  v-for="subItem in item.subs"
                  :key="subItem.text"
                  class="nav-link nav-link__sub-group"
                  active-class="active"
                  :to="subItem.to"
                >
                  <v-list-item>
                    <v-list-item-action>
                      <s-icon
                        class="nav-link__icon"
                        :name="subItem.icon"
                        size="26px"
                      ></s-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-text="subItem.text"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </router-link>
              </v-list-group>
              <router-link
                v-else
                class="nav-link"
                active-class="active"
                :to="item.to"
                :key="index"
              >
                <v-list-item
                  :key="index"
                  link
                >
                  <v-list-item-action>
                    <s-icon
                      class="nav-link__icon"
                      :name="item.icon"
                      size="26px"
                    />
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.text }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </router-link>
            </template>
          </v-list>
        </s-navigation-drawer>
      </div>
      <v-main
        class="page-container"
      >
        <top-nav />
        <section class="page px-5">
          <router-view />
        </section>
      </v-main>
    </div>
  </v-app>
</template>
<script>
import TopNav from '@/components/TopNav.vue'
export default {
  name: 'App',
  components: {
    'top-nav': TopNav,
  },
  data: () => ({
    isLoading: false,
    items: [
      { icon: 'dashboard', text: 'Dashboard', to: '/dashboard' },
      {
        icon: 'customers',
        text: 'Users',
        to: '/users',
        subs: [
          { icon: 'customers-recurring', text: 'Subscribed', to: '/users/subscriptions' },
          { icon: 'customers-referral', text: 'Referrals', to: '/users/referrals' },
          { icon: 'customers', text: 'All Users', to: '/users/all' },
        ],
      },
      { icon: 'settings', text: 'Debug', to: '/debug' },
      { icon: 'settings', text: 'Message Deliverability', to: '/deliverability' },
      { icon: 'audit', text: 'Transactions', to: '/transactions' },
      { icon: 'audit', text: 'P2P Transactions', to: '/p2p-transactions' },
      { icon: 'outgoing-call', text: 'Call Activity', to: '/calls' },
      { icon: 'rules', text: 'Training Data', to: '/training' },
    ],
  }),
  methods: {
    isNavActive (nav) {
      if (this.$route.path.indexOf(nav) !== -1) return true
      return false
    },
    logout () {
      localStorage.clear('jwt')
      localStorage.clear('vuex')
      location.href = '/login'
    },
  },
}
</script>
<style lang="scss">
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_mixins.scss";
.app-drawer {
  &__logo {
    @include app-logo;
  }
}

.app__bar__title {
  @include title;
}
.app__bar__chip {
  background: transparent !important;
  overflow: visible !important;
  padding: 0 !important;
  .app__bar__chip__avatar {
    margin-right: 0.5rem;
  }
}

.v-avatar.app__bar__chip__avatar {
  @include shadow;
}

.app__bar__status-container {
  @include center-children;
  padding: 0 1.5rem;
  height: 100%;
  margin-right: 2rem;
  font-weight: $font-medium;
  border-left: $thin-borderline;
  border-right: $thin-borderline;
  transition: ease all 0.3s;
}
.app__bar__status-container.un-protected {
  color: var(--v-primary-base);
  background: var(--v-dangerAlt-base);
}
.app__bar__status-container.protected {
  color: var(--v-success-base);
  background: var(--v-successAlt-base);
}
.app__bar__status-container__switch {
  transform: translateY(10px);
}
.app__bar__status-container__label {
  min-width: 148px;
}
.nav-link__icon {
  color: var(--v-defaultIconColor-base);
}
.nav-link {
  text-decoration: none;

  & > div {
    border-left: none;
  }
  .v-list-item__action {
    margin-right: 0.9rem !important;
  }
}
.nav-link,
.nav-link__sub-group__parent {
  & .v-list-item__title {
    color: var(--v-grayTextAlt-base) !important;
    font-weight: $font-medium !important;
    font-size: 15px !important;
  }
}
.nav-link__sub-group__parent {
  .v-list-item__action {
    margin-right: 0.9rem !important;
  }
}

.v-list-group__header {
  border-radius: $default-radius !important;
  overflow: hidden;
}

.nav-link__sub-group .v-list-item__action {
  padding-left: 1.6rem !important;
}

.nav-link {
  & > div {
    min-height: 20px !important;
    height: 48px !important;
    border-radius: $default-radius !important;
    overflow: hidden;
  }
}

.router-link-exact-active,
.nav-link.active {
  & > div {
    background: var(--v-accentLight-base) !important;
    border-color: var(--v-accent-base) !important;
  }
  & .v-list-item__title {
    color: var(--v-primary-base) !important;
  }
}
.nav-link.active .nav-link__icon {
  color: var(--v-primary-base);
}

.page {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

@media #{$slaptopdown} {
  .page-container {
    padding: 0 !important;
    height: 100%;
  }

  .tab__card {
    .v-tab {
      font-weight: bold !important;
      text-transform: none !important;
      background: transparent !important;
      color: var(--v-grayTextAlt-base) !important;
      padding: 0 !important;
      min-width: 10px !important;
      width: 80px !important;

      &::before {
        display: none !important;
      }
    }

    .v-tab.v-tab--active {
      color: var(--v-primary-base) !important;
    }

    .v-tabs-slider {
      display: none;
    }

    .v-slide-group__prev {
      display: none !important;
    }
    .v-slide-group__wrapper {
      width: 100vw !important;
    }

    .v-tabs-bar__content {
      display: flex !important;
      place-content: center !important;
      place-items: center;
      overflow: hidden !important;
    }
  }
}

@media #{$smalldown} {
  .tab__card {
    .v-tab {
      font-size: $text-xm !important;
    }
  }
}

.tab__card {
  position: relative;
  bottom: 0px !important;
  left: 0;
}

.v-list-item {
  margin-bottom: 5px !important;
}

.app__bar__status-container.show {
  opacity: 1;
  touch-action: auto;
  pointer-events: all;
}

.app__bar__status-container.hide {
  opacity: 0;
  touch-action: none;
  pointer-events: none;
}

.topbar {
  &__control {
    width: 240px;
  }
}
</style>
